body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;
}

#root {
  height: 100%;
}

.wrapper {
  max-width: 500px;
  min-width: 300px;
  box-shadow: 3px 3px 14px 5px rgba(0,0,0,0.1);
}

.link {
  color: var(--bs-primary);
  cursor: pointer;
  text-decoration: underline;
}
.link:hover {
  text-decoration: none;
}

.mainContainer {
  min-width: 350px;
  overflow: hidden;
}

.last-input-fix {
  border-top-right-radius: 2rem !important;
  border-bottom-right-radius: 2rem !important;
}

.overflowXNone {
  overflow-x: hidden;
}

.styled-input {
  display: block;
  overflow: hidden;
  border-radius: 5px;
  height: 40px;
  border: 1px solid #ccc;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 8px;
}
  .styled-input:focus {
    outline: none;
  }

label {
  /* padding-left: 10px; */
  font-size: 10pt;
  color: rgb(62, 62, 62);
}

.legal-links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
  background-color: #f8f9fa;
  padding: 5px;
  border-radius: 5px;
}
  .legal-links > a {
    text-align: center;
    font-size: 10pt;
  }

.global-message-div {
  background-color: #f0f0f0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.global-message-div > .global-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;
}